<template>
  <div class="d-flex justify-center mt-12">
    <v-card outlined elevation="8" width="65%" max-width="650">
      <v-form @submit.prevent="handleIntelAuth">
        <v-card-title>Risk Score Intel Level</v-card-title>
        <v-card-text>
          <p>
            Temporary selector to illustrate authorization per
            authentication/role level for Risk Score Intel dashboard. Select a
            level to enter that authorization type of dashboard. The value will
            be cookied until you "sign out" of the dashboard.
          </p>
          <v-text-field
            v-model="user"
            label="First & Last Name"
            outlined
          ></v-text-field>
          <v-select
            v-model="selectedLevel"
            outlined
            :items="items"
            label="Select Intel Level"
          ></v-select>
          <SearchSelectAdvisor
            v-if="selectedLevel === 'Advisor'"
            v-model="selectedAdvisor"
            :required="false"
            :multiple="false"
            label="Search for Advisor"
          />
        </v-card-text>
        <v-card-actions class="mb-4 justify-center">
          <v-btn type="submit" color="primary" :disabled="!selectedLevel">
            Submit
          </v-btn></v-card-actions
        >
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SearchSelectAdvisor from "@/components/SearchSelectAdvisor";

export default {
  components: { SearchSelectAdvisor },
  data() {
    return {
      items: ["Org", "Group", "Advisor"],
      selectedLevel: null,
      user: null,
      selectedAdvisor: null,
    };
  },

  methods: {
    ...mapActions(["setIntelUser"]),
    handleIntelAuth() {
      localStorage.setItem("intelUser", this.user);
      const level = this.selectedLevel.toLowerCase();
      localStorage.setItem("intelLevel", level);
      this.$router.push({
        path: "/intel",
        query: { advisor: this.selectedAdvisor?.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-application {
  background-color: #f0f2f5;
}
</style>
